export const receptionTypes = {
  food: 'food-delivery',
  pkg: 'package',
  tour: 'tour',
  visitor: 'visitation',
  maintenance: 'repair',
  manager: 'assistance',
  other: 'other',
  event: 'event',
  alert: 'package-alert',
};

export const getReceptionTypeTitle = receptionType => {
  switch (receptionType) {
    case receptionTypes.food:
      return 'Food Delivery';
    case receptionTypes.pkg:
      return 'Package Delivery';
    case receptionTypes.tour:
      return 'Tour Arrival';
    case receptionTypes.visitor:
      return 'Visitor';
    case receptionTypes.maintenance:
      return 'Maintenance';
    case receptionTypes.manager:
      return 'Assistance Needed';
    case receptionTypes.other:
      return 'Assistance Needed';
    case receptionTypes.event:
      return 'Event';
    case alert:
      return 'Package Alert';
    default:
      return 'Assistance Needed';
  }
};

export const getSubjectLine = (receptionType, visitorName, memberName) => {
  switch (receptionType) {
    case receptionTypes.food:
      return `${memberName}’s food has arrived`;
    case receptionTypes.pkg:
      return `${visitorName} is here to deliver a package`;
    case receptionTypes.tour:
      return `${visitorName} has arrived for a tour`;
    case receptionTypes.visitor:
      return `${visitorName} is here to see ${memberName}`;
    case receptionTypes.maintenance:
      return `${visitorName} is here for a repair or maintenance`;
    case receptionTypes.manager:
      return `${visitorName} is here to meet with the member experience manager`;
    case receptionTypes.event:
      return `${visitorName} is here for an event`;
    default:
      return `${visitorName} needs assistance`;
  }
};
