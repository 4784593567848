export const HomeRoute = '/home';

export const LIMIT_RESERVATIONS = 50;
export const LIMIT_SCHEDULE_ITEMS = 15;
export const THRESHOLD_INFINITE_SCROLL = 4;
export const ARROW_UP = 'ArrowUp';
export const ARROW_DOWN = 'ArrowDown';

export const LOCALES = ['en', 'fr', 'nl'];
export const LOCALE_LABELS = {
  en: 'English',
  fr: 'Français',
  nl: 'Nederlands',
};

export const EN_US = 'en';

export const CUSTOM_LOCALE_FORMATS = {
  en: {
    longDateFormat: {
      '[MMMM D, YYYY]': 'MMMM D, YYYY',
      '[dddd, MMM D]': 'dddd, MMM D',
      '[dddd, MMMM D]': 'dddd, MMMM D',
      '[MMMM D]': 'MMMM D',
      '[MMM D]': 'MMM D',
      '[ddd, MMM D]': 'ddd, MMM D',
      '[h:mma]': 'h:mma',
      '[dddd, MMMM D, YYYY]': 'dddd, MMMM D, YYYY',
      '[M/DD]': 'M/DD',
      '[MMM. DD, h:mma]': 'MMM. DD, h:mma',
      '[MM/DD/YYYY]': 'MM/DD/YYYY',
      '[h:mm a]': 'h:mm a',
      '[h a]': 'h a',
      '[M/D/YYYY]': 'M/D/YYYY',
    },
  },
  fr: {
    longDateFormat: {
      '[MMMM D, YYYY]': 'D MMMM, YYYY',
      '[dddd, MMM D]': 'dddd, D MMM',
      '[dddd, MMMM D]': 'dddd, D MMMM',
      '[MMMM D]': 'D MMMM',
      '[MMM D]': 'MMM D',
      '[ddd, MMM D]': 'ddd, D MMM',
      '[h:mma]': 'k:mm',
      '[dddd, MMMM D, YYYY]': 'dddd, D MMMM, YYYY',
      '[M/DD]': 'D/MM',
      '[MMM. DD, h:mma]': 'DD MMM., k:mm',
      '[MM/DD/YYYY]': 'DD/MM/YYYY',
      '[h:mm a]': 'k:mm',
      '[h a]': 'k',
      '[M/D/YYYY]': 'D/M/YYYY',
    },
  },
  nl: {
    longDateFormat: {
      '[MMMM D, YYYY]': 'D MMMM, YYYY',
      '[dddd, MMM D]': 'dddd, D MMM',
      '[dddd, MMMM D]': 'dddd, D MMMM',
      '[MMMM D]': 'D MMMM',
      '[MMM D]': 'MMM D',
      '[ddd, MMM D]': 'ddd, D MMM',
      '[h:mma]': 'k:mm',
      '[dddd, MMMM D, YYYY]': 'dddd, D MMMM, YYYY',
      '[M/DD]': 'D/MM',
      '[MMM. DD, h:mma]': 'DD MMM., k:mm',
      '[MM/DD/YYYY]': 'DD/MM/YYYY',
      '[h:mm a]': 'k:mm',
      '[h a]': 'k',
      '[M/D/YYYY]': 'D/M/YYYY',
    },
  },
  es: {
    longDateFormat: {
      '[MMMM D, YYYY]': 'D MMMM, YYYY',
      '[dddd, MMM D]': 'dddd, D MMM',
      '[dddd, MMMM D]': 'dddd, D MMMM',
      '[MMMM D]': 'D MMMM',
      '[MMM D]': 'MMM D',
      '[ddd, MMM D]': 'ddd, D MMM',
      '[h:mma]': 'k:mm',
      '[dddd, MMMM D, YYYY]': 'dddd, D MMMM, YYYY',
      '[M/DD]': 'D/MM',
      '[MMM. DD, h:mma]': 'DD MMM., k:mm',
      '[MM/DD/YYYY]': 'DD/MM/YYYY',
      '[h:mm a]': 'k:mm',
      '[h a]': 'k',
      '[M/D/YYYY]': 'D/M/YYYY',
    },
  },
  de: {
    longDateFormat: {
      '[MMMM D, YYYY]': 'D MMMM, YYYY',
      '[dddd, MMM D]': 'dddd, D MMM',
      '[dddd, MMMM D]': 'dddd, D MMMM',
      '[MMMM D]': 'D MMMM',
      '[MMM D]': 'MMM D',
      '[ddd, MMM D]': 'ddd, D MMM',
      '[h:mma]': 'k:mm',
      '[dddd, MMMM D, YYYY]': 'dddd, D MMMM, YYYY',
      '[M/DD]': 'D/MM',
      '[MMM. DD, h:mma]': 'DD MMM., k:mm',
      '[MM/DD/YYYY]': 'DD/MM/YYYY',
      '[h:mm a]': 'k:mm',
      '[h a]': 'k',
      '[M/D/YYYY]': 'D/M/YYYY',
    },
  },
};

export const noOp = () => null;

export const MAX_IMAGE_SIZE = 7000000;

export const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const WAIT_TIME_FOR_SUBMIT_BUTTON_SUCCESS = 500;

export const UNLIMITED_PASS_VALUE = -1;

export const ADMIN_PORTAL_URL = 'https://admin-portal.industriousoffice.com';
