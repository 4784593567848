/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import {
  InstacartBusinessLogo,
  IndustriousSelectsLogo,
  NatureBoxLogo,
} from '../../../resources/logos';

export const PartnershipShape = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoSetPaths: PropTypes.string,
  body: PropTypes.string.isRequired,

  linkButtonText: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,

  clickTrackingLabel: PropTypes.string.isRequired,
};

export const Partnerships = [
  {
    id: 1,
    clickTrackingLabel: 'Selects',
    value: 'perks.selects.value',
    body: 'perks.selects.body',
    logo: IndustriousSelectsLogo,
    linkUrl: 'https://bit.ly/indselects',
  },
  {
    id: 2,
    value: 'perks.nature_box.value',
    body: 'perks.nature_box.body',
    logo: NatureBoxLogo,
    linkUrl: 'https://naturebox.com/industrious',
    clickTrackingLabel: 'NatureBox',
  },
  {
    id: 3,
    clickTrackingLabel: 'Instacart Business',
    value: 'perks.instacart_business.value',
    body: 'perks.instacart_business.body',
    logo: InstacartBusinessLogo,
    linkUrl: 'http://instacart.com/business?bcode=industriousetybdj',
  },
];
