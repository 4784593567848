import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Theme, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { getCanAccessBillingPortal } from '../../Redux/Common/UserManager/selectors';
import { breakpoints, colors } from '../../Core/Theme';

interface BillingLinkProps {
  className?: string;
  classes: ClassNameMap;
}

const BillingLink = ({ className, classes }: BillingLinkProps) => {
  const { t } = useTranslation();
  const canAccessBillingPortal = useSelector(getCanAccessBillingPortal);
  if (!canAccessBillingPortal) {
    return null;
  }

  return (
    <div className={classes.container}>
      <a href="/billing/" className={className}>
        {t('billingPortalLink.name')}
      </a>
    </div>
  );
};

BillingLink.defaultProps = {
  className: '',
};

const styles = (theme: Theme) => ({
  labelChip: {
    marginLeft: 10,
    background: colors.palette.secondary.main,
    color: colors.black,
    fontSize: 13,
    border: 'none',
    [theme.breakpoints.up(breakpoints.MD)]: {
      background: colors.palette.primary.main,
      color: colors.white,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    [theme.breakpoints.up(breakpoints.MD)]: {
      marginLeft: 5,
    },
  },
});

// @ts-ignore
export default withStyles(styles)(BillingLink);
