import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Components
import { AuthPage, AuthBody } from '../../Components/Auth';
import { Text } from '../../Components/Common';
import SubmitEmail from './SubmitEmail';
import SubmitCode from './SubmitCode';

// Styling
import styles from './styles';

const ForgotPW = props => {
  const { classes, location, history } = props;
  const { t } = useTranslation();

  const [view, setView] = React.useState('email');
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [displayMsg, setDisplayMsg] = React.useState('');
  const [email] = React.useState(
    location.state && location.state.email ? location.state.email : ''
  );
  const [code] = React.useState(
    location.state && location.state.code ? location.state.code : ''
  );

  React.useEffect(() => {
    if (location.state && location.state.view) {
      setView('code');
    }
  }, []);

  React.useEffect(() => {
    if (isSuccess && view === 'code') {
      setTimeout(() => history.push('/login'), 2500);
    }
  }, [view, isSuccess]);

  return (
    <AuthPage>
      <AuthBody styleName="formShort">
        <React.Fragment>
          <div className={classes.title}>
            <Text text={t('forgotPW.reset_your_password')} />
          </div>

          {view === 'email' ? (
            <SubmitEmail
              isLoading={isLoading}
              setLoading={setLoading}
              isSuccess={isSuccess}
              setSuccess={setSuccess}
              setDisplayMsg={setDisplayMsg}
              displayMsg={displayMsg}
            />
          ) : (
            <SubmitCode
              isLoading={isLoading}
              setLoading={setLoading}
              isSuccess={isSuccess}
              setSuccess={setSuccess}
              setDisplayMsg={setDisplayMsg}
              displayMsg={displayMsg}
              email={email}
              code={code}
              setView={setView}
            />
          )}
        </React.Fragment>
      </AuthBody>
    </AuthPage>
  );
};

ForgotPW.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ForgotPW);
