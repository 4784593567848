export default {
  deskSvg: '2020-07/deskIcon.svg',
  meetingDeskSvg: '2020-07/meetingDeskIcon.svg',
  deskLandingBackground: '2020-07/onDemandLanding.jpg',
  deskWaiting1: '2020-08/deskWaiting/deskWaiting.png',
  deskWaiting2: '2020-08/deskWaiting/deskWaiting@2x.png',
  deskWaiting3: '2020-08/deskWaiting/deskWaiting@3x.png',
  underConstruction1: '2020-09/underConstruction/underConstruction.png',
  underConstruction2: '2020-09/underConstruction/underConstruction@2x.png',
  underConstruction3: '2020-09/underConstruction/underConstruction@3x.png',
  errorCarat1: '2020-10/errorCarat/errorCarat.png',
  errorCarat2: '2020-10/errorCarat/errorCarat@2x.png',
  errorCarat3: '2020-10/errorCarat/errorCarat@3x.png',
  emptyMoon1: '2020-12/emptyMoon/emptyMoon.png',
  emptyMoon2: '2020-12/emptyMoon/emptyMoon@2x.png',
  emptyMoon3: '2020-12/emptyMoon/emptyMoon@3x.png',
  exclaimAlert1: '2021-01/exclaimAlert/exclaimAlert.png',
  exclaimAlert2: '2021-01/exclaimAlert/exclaimAlert@2x.png',
  exclaimAlert3: '2021-01/exclaimAlert/exclaimAlert@3x.png',
  felixGrayLogo: '2021-11/FelixGrayLogo.png',
  eventsDefault: '2022-02/Event_Default.jpg',
  openWorkspaceBackground: '2022-02/CommunitySeatsBackground.jpeg',
};
