/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useMemo } from 'react';
import { trackLocationDropdownClick } from '../../../Core/Tracking';

import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';

import {
  getIsDisplayGroupingsLoading,
  getLocationGroupings,
  getUserDefaultGrouping,
} from '../../../Redux/Common/Groupings/selectors';
import { filterLocationsForFeatureToggle, makeOption } from './utils';

import BaseLocationList from '../BaseLocationList';

const GroupingLocationList = props => {
  const {
    onChange,
    gaCategory,
    selectedGrouping,
    isLoading,
    hasError,
    usersDefaultGrouping,
    locationGroupings,
    profile,
    featureToggleKey,
    withoutViewHomeLocation,
    isLoadingBookableGroupings,
  } = props;

  const { t } = useTranslation();
  const userLocation = profile?.primaryLocation;

  const selectWrapped = l => {
    if (gaCategory) {
      trackLocationDropdownClick(l?.displayName, gaCategory);
    }
    onChange(l);
  };

  const onReturnHome = () => selectWrapped(usersDefaultGrouping);

  const getShouldRenderReturnHome = () => {
    if (isLoading || isLoadingBookableGroupings || withoutViewHomeLocation)
      return false;

    if (!usersDefaultGrouping?.openToCoworkingMembers) {
      return selectedGrouping?.idGrouping !== usersDefaultGrouping?.idGrouping;
    }
    return selectedGrouping?.locationSfId !== userLocation?.sfId;
  };

  const options = useMemo(
    () =>
      filterLocationsForFeatureToggle(
        profile,
        locationGroupings,
        featureToggleKey
      ).map(makeOption(t)),
    [featureToggleKey, locationGroupings.length]
  );

  const getIsItemSelected = item => {
    return selectedGrouping?.idGrouping === item.value.idGrouping;
  };

  return (
    <BaseLocationList
      {...props}
      options={options}
      value={selectedGrouping}
      hasError={hasError}
      onReturnHome={onReturnHome}
      shouldRenderReturnHome={getShouldRenderReturnHome()}
      onChange={selectWrapped}
      getIsItemSelected={getIsItemSelected}
      isLoading={isLoading || isLoadingBookableGroupings}
      loadingDisplayValue={profile?.primaryLocation?.externalName}
      disabledTooltipText={t('general.disabled_location')}
    />
  );
};

GroupingLocationList.defaultProps = {
  datatestid: '',
  gaCategory: '',
  withoutViewHomeLocation: false,
  selectedGrouping: {},
  renderValue: null,
  hasError: false,
  featureToggleKey: '',
  isLoading: false,
  titleKey: 'locationEditor.select_location',
  label: undefined,
};

GroupingLocationList.propTypes = {
  onChange: PropTypes.func.isRequired,
  gaCategory: PropTypes.string,
  datatestid: PropTypes.string,
  withoutViewHomeLocation: PropTypes.bool,
  selectedGrouping: PropTypes.object,
  renderValue: PropTypes.func,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  usersDefaultGrouping: PropTypes.object.isRequired,
  isLoadingBookableGroupings: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  locationGroupings: PropTypes.array.isRequired,
  featureToggleKey: PropTypes.string,
  titleKey: PropTypes.string,
  label: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: getUserProfile(state),
    usersDefaultGrouping:
      ownProps.usersDefaultGrouping ?? getUserDefaultGrouping(state),
    locationGroupings:
      ownProps.locationGroupings ?? getLocationGroupings(state),
    isLoadingDisplayGroupings: getIsDisplayGroupingsLoading(state),
  };
};

export default connect(mapStateToProps)(GroupingLocationList);
