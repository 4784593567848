import { createSelector } from 'reselect';
import Lodash from 'lodash';

// Selectors
import { getEvents as getEventsState } from '../../Events/Redux/selectors';

// Utils
import { date as DateUtils } from '../../../Core/Utils';

// Models

// Constants
import { getAllDisplayGroupings } from '../../../Redux/Common/Groupings/selectors';
import { getIsVirtualMember } from '../../../Core/Utils/userPermissions';
import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';
import { ManagerRoles } from '../../../Core/Api/dto/ManagerRoles.enum';

export const getHome = state => Lodash.get(state, ['home'], {});

export const getSelectedLocation = createSelector(
  getHome,
  home => home.selectedGrouping?.location
);
export const getSelectedGrouping = createSelector(
  getHome,
  home => home.selectedGrouping
);

export const getQueriedGroupings = createSelector(
  getHome,
  home => home.queriedGroupings
);

export const getIntegratedGroupingsForHomePage = createSelector(
  getSelectedGrouping,
  getAllDisplayGroupings,
  (selectedGrouping, bookableGroupings) => {
    return bookableGroupings.filter(
      g =>
        g.type === 'Integrated' &&
        g.locationSfId === selectedGrouping.locationSfId
    );
  }
);

export const getOccasionsForGrouping = createSelector(
  getSelectedGrouping,
  getEventsState,
  getUserProfile,
  (grouping, events, userProfile) => {
    if (!grouping?.idGrouping) {
      return [];
    }
    const occasionsAllIds = Lodash.get(events, ['occasions', 'allIds'], []);
    const occasionsById = Lodash.get(events, ['occasions', 'byId'], {});

    const locationOccasions = occasionsAllIds.reduce((acc, id) => {
      const occasion = occasionsById[id];
      if (
        occasion &&
        occasion.groupingId &&
        occasion.groupingId === grouping?.idGrouping &&
        occasion.endEpoch >= DateUtils.getExactTimeInUnix()
      ) {
        acc.push(occasionsById[id]);
      } else if (getIsVirtualMember(userProfile)) {
        acc.push(occasionsById[id]);
      }
      return acc;
    }, []);
    return Lodash.sortBy(locationOccasions, 'startEpoch');
  }
);

const getLocationDetails = createSelector(
  getHome,
  home => home.locationDetails
);

export const getLocationDetailsLoading = createSelector(
  getLocationDetails,
  locationDetails => locationDetails.isLoading
);

export const getLocationDetailsHasError = createSelector(
  getLocationDetails,
  locationDetails => locationDetails.hasError
);

export const getLocationDetailsById = createSelector(
  getLocationDetails,
  locationDetails => {
    return locationDetails.data.byId;
  }
);

export const getLocationDetailsAllIds = createSelector(
  getLocationDetails,
  locationDetails => locationDetails.data.allIds
);

export const getLocationDetailsForSelectedLocation = createSelector(
  [getLocationDetailsById, getSelectedLocation],
  (locationDetailsById, selectedLocation) => {
    return locationDetailsById?.[selectedLocation?.sfId];
  }
);

export const getCommunityManagerForSelectedLocation = createSelector(
  getLocationDetailsForSelectedLocation,
  locationDetails => {
    const allManagers = Lodash.get(locationDetails, ['managers'], []);
    const communityManagers = allManagers.filter(manager => {
      return (
        manager &&
        Array.isArray(manager.roles) &&
        manager.roles.includes(ManagerRoles.MemberExperienceManager)
      );
    });
    if (communityManagers.length === 0) {
      return undefined;
    }
    return communityManagers[0];
  }
);
