import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//  Components
import { SubmitButton, Text, FieldInput } from '../../Components/Common';

//  Styling
import styles from './styles';

import { submitEmail } from './utils';

const handleEnter = (setLoading, email, setSuccess, setDisplayMsg) => e => {
  if (e.nativeEvent.type === 'click' || e.key === 'Enter') {
    submitEmail(setLoading, email, setSuccess, setDisplayMsg);
  }
};

const SubmitEmail = props => {
  const {
    classes,
    isLoading,
    isSuccess,
    setLoading,
    setSuccess,
    setDisplayMsg,
    displayMsg,
  } = props;
  const { t } = useTranslation();
  const [email, inputEmail] = React.useState('');

  let error = '';
  if (displayMsg !== '' && !(isSuccess || isLoading)) {
    error = <div className={classes.errorMsg}>{displayMsg}</div>;
  }
  return (
    <React.Fragment>
      <div className={classes.message}>
        <Text text={t('forgotPW.submit_description')} />
      </div>
      {error}
      {!isSuccess ? (
        <div className={classes.inputsContainer}>
          <FieldInput
            isLabelHidden
            className={classes.fieldInputStyle}
            placeholder="Email"
            variant="standard"
            value={email}
            onChange={inputEmail}
            inputProps={{
              style: { paddingLeft: 10, paddingRight: 10 },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              disableUnderline: true,
              className: classes.inputRoot,
            }}
            onKeyDown={handleEnter(
              setLoading,
              email,
              setSuccess,
              setDisplayMsg
            )}
            label={t('forgotPW.email')}
            id="emailInput"
          />

          <SubmitButton
            isLoading={isLoading}
            isSuccess={isSuccess}
            defaultText={t('forgotPW.reset_password')}
            onClick={handleEnter(setLoading, email, setSuccess, setDisplayMsg)}
            className={classes.resetPasswordButton}
          />
        </div>
      ) : (
        <div className={classes.sentEmail} datatestid="email_sent_confirmation">
          {t('forgotPW.success')}
        </div>
      )}
    </React.Fragment>
  );
};

SubmitEmail.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setDisplayMsg: PropTypes.func.isRequired,
  displayMsg: PropTypes.string.isRequired,
};

export default withStyles(styles)(SubmitEmail);
